<template>
  <Menu class="ivu-menu-custom" :open-names="allMenuNames"
        :active-name="$route.name" width="auto">
    <Submenu v-for="subMenu in menuList" :key="subMenu.name" :name="subMenu.name">
      <template slot="title">
        <Icon type="ios-paper" />
        {{ subMenu.title }}
      </template>
      <MenuItem
        v-for="menuItem in subMenu.children"
        :key="menuItem.name"
        :name="menuItem.name"
        :to="{ name: menuItem.name }">
        {{ menuItem.title }}
      </MenuItem>
    </Submenu>
  </Menu>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import configRoute from '@/router/configRoute';

export default {
  name: 'ConfigMenu',
  data() {
    return {
      menuList: [],
    };
  },
  computed: {
    allMenuNames() {
      return this.menuList.map((x) => x.name);
    },
  },
  created() {
    this.menuList = this.getMenusByRoutes(configRoute);
  },
  methods: {
    getMenusByRoutes(routes) {
      const menus = [];
      routes.forEach((item) => {
        if (item.meta && !item.meta.hidden) {
          const obj = {
            name: item.name,
            ...item.meta,
          };
          if (item.children && item.children.length > 0) {
            obj.children = this.getMenusByRoutes(item.children);
          }
          menus.push(obj);
        }
      });
      return menus;
    },
  },
};
</script>

<style scoped lang="less">
@menuBg:#32333a;
@menuItemHeight: 40px;
@menuItemLineHeight: @menuItemHeight;
@menuHoverItemBg:
  linear-gradient(270deg, rgba(104, 118, 246, 0.06) 0%, rgba(104, 118, 246, 0.2) 100%);
@menuSelectedItemBg: linear-gradient(270deg, rgba(104, 118, 246, 0.1) 0%, #6876F6 100%);
@menuItemIconMargin: 0 10px 0 16px;
@menuItemNameFontSize: 14px;
@menuItemNameFontWeight: 400;
@menuItemNameColor: #ffffff;
.ivu-menu-custom {
  background: @menuBg;
  color: #fff;

  &.ivu-menu-vertical .ivu-menu-item-group-title {
    color: rgba(255, 255, 255, 0.36);
  }
  &.ivu-menu-vertical .ivu-menu-item,
  &.ivu-menu-vertical .ivu-menu-submenu-title {
    color: #fff;
    padding: 10px 24px;
  }
  &.ivu-menu-vertical .ivu-menu-item-active:not(.ivu-menu-submenu),
  &.ivu-menu-vertical .ivu-menu-item-active:not(.ivu-menu-submenu):hover,
  &.ivu-menu-vertical .ivu-menu-submenu-title-active:not(.ivu-menu-submenu),
  &.ivu-menu-vertical .ivu-menu-submenu-title-active:not(.ivu-menu-submenu):hover {
    background: @menuHoverItemBg;
  }
   &.ivu-menu-vertical /deep/ .ivu-menu-item:hover,
   &.ivu-menu-vertical /deep/ .ivu-menu-submenu-title:hover {
    color: #fff;
    background: @menuHoverItemBg;
  }
  &.ivu-menu-vertical .ivu-menu-item-active:not(.ivu-menu-submenu),
  &.ivu-menu-vertical .ivu-menu-submenu-title-active:not(.ivu-menu-submenu) {
    color: @menuSelectedItemBg;
  }
  &.ivu-menu-vertical .ivu-menu-submenu .ivu-menu-item:hover {
    color: #fff;
    background: @menuHoverItemBg;
  }
  &.ivu-menu-vertical .ivu-menu-submenu .ivu-menu-item-active,
  &.ivu-menu-vertical .ivu-menu-submenu .ivu-menu-item-active:hover {
    border-right: none;
    color: #fff;
    background: @menuSelectedItemBg !important;
  }
  &.ivu-menu-vertical .ivu-menu-child-item-active > .ivu-menu-submenu-title {
    color: #fff;
  }
  &.ivu-menu-vertical .ivu-menu-opened {
    background: inherit;
  }
  &.ivu-menu-vertical .ivu-menu-opened .ivu-menu-submenu-title {
    background: inherit;
  }
  &.ivu-menu-vertical .ivu-menu-opened .ivu-menu-submenu-has-parent-submenu .ivu-menu-submenu-title
  {
    background: 0 0;
  }
  &.ivu-menu-vertical:after{
    display: none;
  }
  &.ivu-menu-vertical .ivu-menu-item-active:not(.ivu-menu-submenu):after{
    display: none;
  }
}

</style>
